<template>
  <div>
    <v-row>
      <v-col
        cols="6"
      >
        <v-list-item-group class="pt-6">
          <v-alert
            icon="mdi-fire"
            type="success"
            dense
            class="rounded-lg"
            style="cursor: pointer;"
            v-if="user.Config.Premium.Active"
            @click="showSubscriptionDetails(user.Config.Premium)"
          >
            <v-row align="center">
              <v-col class="grow">
                Premium account
              </v-col>
              <v-col class="shrink">
                <s-btn
                  x-small
                  elevation="0"
                  color="success"
                >
                  <s-icon color="white" name="arrow-circle-right" />
                </s-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-list-item-content class="py-4 pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Username</s-text>
            <s-text weight="bold" size="lg" color="black" class="mb-4">
              {{ isUsernameNumeric(user.username) }}{{ user.username }}
              <span
                v-if="user.verified"
                class="count-box bg-white ml-2"
              >
                <v-icon v-if="user.verified" color="green" style="font-size: 16px;">mdi-check</v-icon> verified
              </span>
            </s-text>
          </v-list-item-content>
          <v-list-item-content class="top-thin-border py-4 pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">User ID</s-text>
            <s-text weight="bold" size="md-m" color="black" class="mb-4">
              {{ user.user_id }}
              <copy-to-clipboard :data="user.user_id" />
            </s-text>
          </v-list-item-content>
          <v-list-item-content class="top-thin-border py-4 pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Handle</s-text>
            <s-text weight="bold" size="md-m" color="black" class="mb-4">
              {{ user.handle || "n/a" }}
              <copy-to-clipboard :data="user.handle" />
            </s-text>
          </v-list-item-content>
          <v-list-item-content class="top-thin-border py-4 pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Account Name</s-text>
            <div class="d-flex align-center">
              <a :href="userAvatarImage" target="_blank">
                <v-avatar class="mr-4" width="30">
                  <v-img
                    aspect-ratio="1"
                    cover
                    :lazy-src="require(`@/assets/avatars/user.svg`)"
                    :src="userAvatarImage"
                  />
                </v-avatar>
              </a>
              <s-text weight="bold" size="md-m" color="black">
                {{ user.bio.name || "n/a" }}
                <copy-to-clipboard
                  v-if="user.bio.name !== ''"
                  :data="user.bio.name"
                />
              </s-text>
            </div>
          </v-list-item-content>
          <v-list-item-content class="top-thin-border py-4 pl-0 mb-4">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">
              Devices
            </s-text>
            <div
              v-if="user.devices.length > 0"
              class="app-container"
            >
              <div
                class="app-endpoint"
                v-for="device in user.devices"
                :key="device.id"
                @click="showDeviceDetails(device)"
              >
                <v-icon
                  class="pl-3"
                >
                  mdi-devices
                </v-icon>
                <div
                  class="text-black pl-4"
                >
                  {{ device.device_type || 'Unknown device' }} / {{ device.telco || 'Unknown telco' }}
                </div>
                <div
                  class="text-right pr-4 pt-1"
                >
                  <s-icon color="gray" name="arrow-circle-right" />
                </div>
              </div>
            </div>
            <div v-else>
              <s-text size="lg" weight="bold">
                No devices currently registered.
              </s-text>
            </div>
          </v-list-item-content>
          <v-list-item-content :ripple="false" class="top-thin-border pl-0 pt-3">
            <v-col class="pl-0">
              <s-text
                weight="normal"
                size="sm"
                color="grayTextAlt"
                class="mb-4"
              >
                Date Created
              </s-text>
              <s-text size="md-m" weight="bold">
                {{ user.created_at ? getHumanReadableDate(user.created_at) : "-" }}
              </s-text>
            </v-col>
          </v-list-item-content>
        </v-list-item-group>
        <div class="d-flex align-center pt-8">
          <s-btn
            weight="medium"
            size="md-m"
            color="primary"
            elevation="0"
            @click.stop="showDeleteDialog = true"
          >
            <v-icon class="mr-2" color="#fff">mdi-trash-can-outline</v-icon>
            Delete User
          </s-btn>
        </div>
      </v-col>
      <v-col>
        <s-card class="stats-card pa-4 pb-0 pa-lg-6 pb-lg-0 overflow-hidden my-6">
          <div class="d-flex align-center">
            <v-row>
              <v-col>
                <s-text weight="bold" size="md-m" class="d-flex">
                  Wallet Balance
                </s-text>
                <s-text weight="bold" size="md-m" color="primary" class="py-4">
                  NGN {{ userWalletBalance }}
                </s-text>
              </v-col>
              <v-col>
                <s-text weight="bold" size="md-m" class="d-flex">
                  Zerodata Balance
                </s-text>
                <s-text weight="bold" size="md-m" color="primary" class="py-4">
                  {{ userZerodataBalance }} MB
                </s-text>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex align-center top-thin-border pt-5">
            <v-row>
              <v-col>
                <s-text weight="bold" size="md-m" class="d-flex">
                  Points Balance
                </s-text>
                <s-text weight="bold" size="md-m" color="primary" class="py-4">
                  R$ {{ userPointsBalance }}
                </s-text>
              </v-col>
            </v-row>
          </div>
        </s-card>
        <s-card class="stats-card pa-4 pb-0 pa-lg-6 pb-lg-0 overflow-hidden my-6">
          <div class="d-flex justify-space-between align-center">
            <s-text weight="bold" size="md-m" class="d-flex">
              Retrieve Active OTP
              <v-btn
                depressed
                icon
                class="refresh-btn"
                @click="getLatestOTP()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </s-text>
          </div>

          <div class="d-flex align-center mt-4" v-if="!isFetching">
            <v-row>
              <v-col cols="12" class="text-center">
                <s-text weight="bold" size="m-lg" color="primary" class="py-4" style="letter-spacing: 10px">
                  {{ latestOTP }}
                </s-text>
              </v-col>
            </v-row>
          </div>
          <div v-else class="d-flex pa-12">
            <v-progress-circular
              indeterminate
              class="ma-auto"
              color="primary"
              :size="20"
              :width="3"
            />
          </div>
        </s-card>
        <s-card
          class="stats-card overflow-hidden pa-0"
        >
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              key="config"
            >
              <v-expansion-panel-header>
                <span class="d-flex align-center">
                  <s-text weight="bold" size="md-m">
                    User configuration
                  </s-text>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    User can create channel
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserConfig.can_create_channel"
                    :loading="isUpdatingConfig && diffCanCreateChannel"
                    :disabled="isUpdatingConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Enable logging
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserConfig.logging_enabled"
                    :loading="isUpdatingConfig && diffLoggingEnabled"
                    :disabled="isUpdatingConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Enable dev mode
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserConfig.dev_mode_enabled"
                    :loading="isUpdatingConfig && diffDevModeEnabled"
                    :disabled="isUpdatingConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Voice Call Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserConfig.voice_call_enabled"
                    :loading="isUpdatingConfig && diffVoiceCallEnabled"
                    :disabled="isUpdatingConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Video Call Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserConfig.video_call_enabled"
                    :loading="isUpdatingConfig && diffVideoCallEnabled"
                    :disabled="isUpdatingConfig"
                  />
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              key="wallet-config"
            >
              <v-expansion-panel-header>
                <span class="d-flex align-center">
                  <s-text weight="bold" size="md-m">
                    Wallet configuration
                  </s-text>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Wallet Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserWalletConfig.enabled"
                    :loading="isUpdatingWalletConfig && diffWalletEnabled"
                    :disabled="isUpdatingWalletConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    P2P Transafer Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserWalletConfig.enable_p2p_transfer"
                    :loading="isUpdatingWalletConfig && diffWalletP2PTransferEnabled"
                    :disabled="isUpdatingWalletConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    USSD Funding Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserWalletConfig.enable_ussd_funding"
                    :loading="isUpdatingWalletConfig && diffWalletUSSDFundingEnabled"
                    :disabled="isUpdatingWalletConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Bank Transfer Funding Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserWalletConfig.enable_bank_transfer_funding"
                    :loading="isUpdatingWalletConfig && diffWalletBankTransferFundingEnabled"
                    :disabled="isUpdatingWalletConfig"
                  />
                </v-list-item>
                <v-list-item :ripple="false" class="top-thin-border px-0">
                  <s-text style="flex:1">
                    Bank Card Funding Enabled
                  </s-text>
                  <v-switch
                    :inset="true"
                    v-model="currentUserWalletConfig.enable_bank_card_funding"
                    :loading="isUpdatingWalletConfig && diffWalletBankCardFundingEnabled"
                    :disabled="isUpdatingWalletConfig"
                  />
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </s-card>
      </v-col>
    </v-row>
    <s-dialog
      v-model="showDialog"
      persistent
      isModal
      :title="dialogTitle"
      :close-default="false"
      @close="closeAllModals()"
    >
      <delete-user
        v-if="showDeleteDialog"
        :is-deleting="isDeleting"
        @delete="deleteUser"
        @close="showDeleteDialog = false"
      />
      <view-device-details
        v-if="showDeviceDetailsModal"
        :device="device"
        @wake="sendPushNotification({ type: 'wakeup' })"
        @prekeyslow="sendPushNotification({ type: 'prekeyslow' })"
        @rewardad="sendPushNotification({ type: 'rewardad' })"
        @close="showDeviceDetailsModal = false"
      />
      <view-subscription-summary
        v-if="showSubscriptionDetailsModal"
        :subscription="subscription"
        @close="showSubscriptionDetailsModal = false"
        @view="viewUserSubscription"
      />
    </s-dialog>
  </div>
</template>
<script>
import DeleteUser from '@/components/apps/DeleteUser'
import ViewDeviceDetails from '@/components/apps/ViewDeviceDetails'
import ViewSubscriptionSummary from '@/components/apps/ViewSubscriptionSummary'
import CopyToClipboard from '@/components/cards/CopyToClipboard'
import { mapGetters } from 'vuex'
import { getHumanReadableDate } from '@/utils/date'
import { isUsernameNumeric } from '@/utils/fmt'
export default {
  name: 'AppDetails',
  components: {
    'delete-user': DeleteUser,
    'view-device-details': ViewDeviceDetails,
    'view-subscription-summary': ViewSubscriptionSummary,
    'copy-to-clipboard': CopyToClipboard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isDeleting: false,
      showDeleteDialog: false,
      showDeviceDetailsModal: false,
      showSubscriptionDetailsModal: false,
      device: null,
      subscription: null,
      showModal: this.$route.meta.showModal,
      showViewModal: this.$route.meta.showViewModal,
      isFetching: false,
      isUpdatingConfig: false,
      isUpdatingWalletConfig: false,
      latestOTP: '******',
      currentUserConfig: {},
      currentUserWalletConfig: {},
      panel: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/currentUser',
      userConfig: 'user/currentUserConfig',
      userWalletConfig: 'user/currentUserWalletConfig',
    }),
    showDialog () {
      return this.showDeleteDialog || this.showDeviceDetailsModal || this.showSubscriptionDetailsModal
    },
    dialogTitle () {
      if (this.showDeleteDialog) return 'Delete User'
      if (this.showDeviceDetailsModal) return 'Device Details'
      if (this.showSubscriptionDetailsModal) return 'Subscription Details'
      return 'Dialog'
    },
    userAvatarImage () {
      return `${process.env.VUE_APP_API_DOMAIN}/v1/users/${this.id}/picture`
    },
    userWalletBalance () {
      if (this.user.wallet === 0) return 0
      if (this.user.wallet < 0) return 0
      return (this.user.wallet / 100).toFixed(2)
    },
    userZerodataBalance () {
      if (this.user.zerodata_credit === undefined || this.user.zerodata_credit === null) return 0
      if (this.user.zerodata_credit === 0) return 0
      if (this.user.zerodata_credit < 0) return 0
      return (this.user.zerodata_credit / (1024 * 1024)).toFixed(0)
    },
    userPointsBalance () {
      if (this.user.points === undefined || this.user.points === null) return 0
      return (this.user.points / 100).toFixed(2)
    },
    /** computed user config */
    diffCanCreateChannel () {
      return (this.userConfig.can_create_channel !== this.currentUserConfig.can_create_channel)
    },
    diffLoggingEnabled () {
      return (this.userConfig.logging_enabled !== this.currentUserConfig.logging_enabled)
    },
    diffDevModeEnabled () {
      return (this.userConfig.dev_mode_enabled !== this.currentUserConfig.dev_mode_enabled)
    },
    diffVoiceCallEnabled () {
      return (this.userConfig.voice_call_enabled !== this.currentUserConfig.voice_call_enabled)
    },
    diffVideoCallEnabled () {
      return (this.userConfig.video_call_enabled !== this.currentUserConfig.video_call_enabled)
    },
    /** computed wallet config */
    diffWalletEnabled () {
      return (this.userWalletConfig.enabled !== this.currentUserWalletConfig.enabled)
    },
    diffWalletP2PTransferEnabled () {
      return (this.userWalletConfig.enable_p2p_transfer !== this.currentUserWalletConfig.enable_p2p_transfer)
    },
    diffWalletUSSDFundingEnabled () {
      return (this.userWalletConfig.enable_ussd_funding !== this.currentUserWalletConfig.enable_ussd_funding)
    },
    diffWalletBankTransferFundingEnabled () {
      return (this.userWalletConfig.enable_bank_transfer_funding !== this.currentUserWalletConfig.enable_bank_transfer_funding)
    },
    diffWalletBankCardFundingEnabled () {
      return (this.userWalletConfig.enable_bank_card_funding !== this.currentUserWalletConfig.enable_bank_card_funding)
    },
  },
  methods: {
    getHumanReadableDate,
    isUsernameNumeric,
    closeAllModals () {
      this.showDeleteDialog = false
      this.showDeviceDetailsModal = false
      this.showSubscriptionDetailsModal = false
    },
    viewUserSubscription () {
      this.$router.push({ name: 'appSubscriptions', params: { id: this.id } })
    },
    showDeviceDetails (device) {
      this.dialog = true
      this.device = device
      this.showDeviceDetailsModal = true
    },
    showSubscriptionDetails (subscription) {
      this.dialog = true
      this.subscription = subscription
      this.showSubscriptionDetailsModal = true
    },
    goBack () {
      this.$router.replace({ name: 'users' })
    },
    async deleteUser () {
      this.isDeleting = true
      await this.$store
        .dispatch('user/deleteUserByPhoneNumber', this.user.username)
        .then(() => {
          this.goBack()
        })
        .finally(() => {
          this.dialog = false
          this.isDeleting = false
          this.goBack()
        })
    },
    async updateUserConfig (key, oldValue) {
      this.isUpdatingConfig = true
      const payload = { id: this.id, config: this.currentUserConfig }
      await this.$store
        .dispatch('user/updateConfig', payload)
        .catch(() => {
          this.currentUserConfig = {
            ...this.currentUserConfig,
            [key]: oldValue,
          }
        })
        .finally(() => {
          this.isUpdatingConfig = false
        })
    },
    async updateUserWalletConfig (key, oldValue) {
      this.isUpdatingWalletConfig = true
      const payload = { id: this.id, config: this.currentUserWalletConfig }
      await this.$store
        .dispatch('user/updateUserWalletConfig', payload)
        .catch(() => {
          this.currentUserWalletConfig = {
            ...this.currentUserWalletConfig,
            [key]: oldValue,
          }
        })
        .finally(() => {
          this.isUpdatingWalletConfig = false
        })
    },
    async getLatestOTP () {
      this.isFetching = true
      await this.$store
        .dispatch('user/getUserOTPByPhoneNumber', this.user.username)
        .then(resp => {
          if (!resp) {
            this.latestOTP = '******'
            return
          }
          this.latestOTP = resp.otp
        })
        .catch(() => {
          this.latestOTP = '******'
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    async sendPushNotification (params) {
      await this.$store
        .dispatch('user/sendPushNotification', { id: this.user.user_id, params })
    },
  },
  watch: {
    '$route.meta' ({ showModal, showViewModal }) {
      this.showModal = showModal
      this.showViewModal = showViewModal
    },
    userConfig: {
      handler (config) {
        this.currentUserConfig = Object.assign({}, config)
      },
      immediate: true,
    },
    userWalletConfig: {
      handler (config) {
        this.currentUserWalletConfig = Object.assign({}, config)
      },
      immediate: true,
    },
    /** user config */
    'currentUserConfig.can_create_channel': {
      handler (_, oldValue) {
        this.updateUserConfig('can_create_channel', oldValue)
      },
    },
    'currentUserConfig.logging_enabled': {
      handler (_, oldValue) {
        this.updateUserConfig('logging_enabled', oldValue)
      },
    },
    'currentUserConfig.dev_mode_enabled': {
      handler (_, oldValue) {
        this.updateUserConfig('dev_mode_enabled', oldValue)
      },
    },
    'currentUserConfig.voice_call_enabled': {
      handler (_, oldValue) {
        this.updateUserConfig('voice_call_enabled', oldValue)
      },
    },
    'currentUserConfig.video_call_enabled': {
      handler (_, oldValue) {
        this.updateUserConfig('video_call_enabled', oldValue)
      },
    },
    /** wallet config */
    'currentUserWalletConfig.enabled': {
      handler (_, oldValue) {
        this.updateUserWalletConfig('enabled', oldValue)
      },
    },
    'currentUserWalletConfig.enable_p2p_transfer': {
      handler (_, oldValue) {
        this.updateUserWalletConfig('enable_p2p_transfer', oldValue)
      },
    },
    'currentUserWalletConfig.enable_ussd_funding': {
      handler (_, oldValue) {
        this.updateUserWalletConfig('enable_ussd_funding', oldValue)
      },
    },
    'currentUserWalletConfig.enable_bank_transfer_funding': {
      handler (_, oldValue) {
        this.updateUserWalletConfig('enable_bank_transfer_funding', oldValue)
      },
    },
    'currentUserWalletConfig.enable_bank_card_funding': {
      handler (_, oldValue) {
        this.updateUserWalletConfig('enable_bank_card_funding', oldValue)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.bg-white {
  background: white;
}

.delete-btn {
  color: #EC3944;
  background-color: #fff !important;
}

.count-box {
  background: #ffffff;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 26px;
  height: 23px;
  color: var(--v-gray-base);;
  font-size: 14px;
  font-weight: 600;
  padding: 4px;
}

.app-container{
  overflow: hidden;
}

.app-endpoint {
  display: grid;
  grid-template-columns: 1fr 5fr 7fr;
  grid-template-rows: 1fr;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #cccccc;
}
.app-endpoint:last-child {
  border-bottom: none,
}
.app-endpoint:hover {
  background: #ffffff;
  cursor: pointer;
}
.app-container {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0) 0%, #ebebeb 100%);
  border: 1.5px solid #cccccc;
  box-sizing: border-box;
  color: #737a91;
  border-radius: 12px;
}

::v-deep .v-image__image--preload {
  filter: none;
}
</style>
