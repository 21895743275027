<template>
  <div class="px-9 my-5">
    <v-list-item-group class="mb-5">
      <v-list-item-content class="py-4 pl-0">
        <v-col class="pl-0">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Status</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ subscription.Active }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border bottom-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Expiration Date
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ subscription.ExpirationDate ? getHumanReadableDate(subscription.ExpirationDate) : "-" }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border bottom-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Last Activation Date
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ subscription.LastActivationDate ? getHumanReadableDate(subscription.LastActivationDate) : "-" }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border bottom-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Last Deactivation Date
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ subscription.LastDeactivationDate ? getHumanReadableDate(subscription.LastDeactivationDate) : "-" }}
          </s-text>
        </v-col>
      </v-list-item-content>
    </v-list-item-group>
    <s-btn
      type="button"
      elevation="0"
      class="mr-4"
      @click="viewSubscriptionsPage"
    >
      View All Subscriptions
    </s-btn>
    <s-btn
      @click="goBack"
      type="button"
      color="primary"
      elevation="0"
    >
      Close
    </s-btn>
  </div>
</template>

<script>
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'ViewSubscriptionSummary',
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getHumanReadableDate,
    goBack () {
      this.$emit('close')
    },
    viewSubscriptionsPage () {
      this.$emit('view')
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
